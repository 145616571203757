@font-face {
  font-family: "FontAwesome";
  src: url(../fonts/fontawesome-webfont.ttf) format("truetype");
}

body.light-login {
  background-color: #131f2e;
}

.navbar {
  background: #131f2e;
}

.nav .light-blue, .ace-nav > li.light-blue > a {
  background-color: #131f2e;
}

.dataTable tfoot h4 {
  font-size: 12px;
  font-weight: bold;
  text-align: right;
}

.btn-primary, .btn-primary.focus, .btn-primary:focus, .login-box .toolbar {
  background-color: #1d6582 !important;
  border-color: #1d6582;
}
